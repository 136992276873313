@import "~antd/dist/antd.less";

@font-face {
  font-family: "NeoSansArabic";
  src: url("../../assets/fonts/NeoSansArabic.ttf");
}

// TOP ORDER ACTIVITY

.component-container {
    border-radius: 16px;
    border: none;
    display: flex;
    flex-direction: column;
    padding: 10px;
    height: fit-content;
    margin-top: 6px;
}

.icon-wrapper {
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;
    border-radius: 8px;
}

.activity-title {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 12px;
    margin-top: 16px;
    margin-bottom: 0px;
}

.activity-value {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
}

// OTHER ACTIVITY
.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.other-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
    background: #f3f5f8;
    border-radius: 16px;
    height: fit-content;
    width: 100%;
}

.other-component {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 16px;
    width: 100%;
    height: 80px;
    background: #ffffff;
    border-radius: 8px;
}

.other-wrapper {
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;
    border-radius: 8px;
    background-color: #d8e0e7;
}
.other-title {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    color: #6e7e8c;
    margin: 10px 0px 0px 0px;
}

.other-value {
    width: 100%;
    height: 30px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    color: #000000;
    white-space: nowrap;
    text-overflow: ellipsis;
    }

.time-container {
    height:100%;
    background-color:#f3f5f8;
    display:flex;
    flex-direction:column;
    padding:21px;
    border-radius:16px;
    row-gap:10px;
    text-align: left;
    width: 100%;
    height: auto;
    margin-top:8px
}

.arabic-title {
    font-family: "NeoSansArabic" !important;
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    color: #000000;
    margin-top: 12px;
}

.map-container {
    height: 73%;
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    background-color: #f3f5f8;
    margin-bottom: 6px;
}

.flex-container {
    column-gap: 5px;
    width: 100%;
    display: flex
}


@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
    .map-container{

    margin-top: 6px;}
}

@media only screen and (max-device-width : 768px) and (orientation : portrait) {
   .map-container{
    margin-top: 6px;
}
}


@primary-color: #0000ff;@primary-text: #0000ff;@primary-color-light: #6666FF;@borderRadiusBase: 20;@error-color: #FF2B5E;@success-color: #00A857;@warning-color: #CC6E00;@text-grey: #6E7E8C;@meduim-grey: #D8E0E7;