@import "~antd/dist/antd.less";

@font-face {
  font-family: "Inter", sans-serif;
  src: url("./assets/fonts/Inter-Regular.otf");
}

&:root{
font-family: "Inter",sans-serif !important;
}

@font-face {
    font-family:"Inter",sans-serif;
    src: url("./assets/fonts/Inter-Thin.otf");
}

* {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
}

.main-container {
    padding: 10px;
}

.activity-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
}

.login-img {
    align-self: center;
    margin-left: 40%;
    margin-right: 0px;
    margin-top: 20px;
}

.custom-input {
    border-radius: 20px;
    width: 100%;
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.input-username {
    border-radius: 20px;
    width: 200px;
}

.charts-container {
    display: flex;
    flex-direction: column;
    margin: 4px auto;
}

.login-page {
  margin-top: 20vh;
}

.login-page img {
  width: 70px;
}
.login-card {
  transform: skewX(-2deg);
}

.login-inputs {
    border-radius: 10px;
}
.ant-divider-horizontal {
    margin:10px 0px;
}
// .logout-btn {
//     background-color: @error-color;
//     right:0px;
//     left:100px;
//     border-radius: 6px;
// }

.table-container {
    padding: 18px;
}
@primary-color: #0000ff;@primary-text: #0000ff;@primary-color-light: #6666FF;@borderRadiusBase: 20;@error-color: #FF2B5E;@success-color: #00A857;@warning-color: #CC6E00;@text-grey: #6E7E8C;@meduim-grey: #D8E0E7;